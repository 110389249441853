import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import List from '../../components/Common/List'
import RequestDemo from '../../components/RequestDemo'
import ContactForm from '../../components/contact-form'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './bim-services.module.scss'

const BimServices: React.FC = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title={t('seo_title_bim_services')}
        description="Unlock the potential of BIM construction software in SIGNAX. Elevate your construction management with BIM and harness the power of building information modeling. Discover BIM-empowered construction management software for your efficient business workflows."
        keywords="bim construction software, bim in construction management, bim building information modeling, bim in construction, bim for construction management, bim modelling software, building information modeling bim software, building information modelling software"
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={t('banner_title_bim_services')}
        colorShadow="dark"
      />
      <Section className={s.section_description}>
        <DescriptionBlock2
          title={t('bim_services_subtitle')}
          description={t('bim_services_description')}
          classNameTitle="mb-4"
        />
      </Section>
      <ModuleSection2>
        <ModuleSectionItem2
          title={t('bim_services_subtitle2')}
          variantTitle="h2"
          description={t('bim_services_description2')}
          btnText={t('Read more')}
          onClick={() => navigate(getPath('/planning-phase/'))}
          columnLeftChildren={
            <div className={s.lists}>
              <p className={s.text}>{t('Read more about our services:')}</p>
              <List>
                <li>{t('bim_services_list2.1')}</li>
                <li>{t('bim_services_list2.2')}</li>
                <li>{t('bim_services_list2.3')}</li>
                <li>{t('bim_services_list2.4')}</li>
                <li>{t('bim_services_list2.5')}</li>
              </List>
            </div>
          }
          columnRightChildren={
            <div className="flex items-center">
              <GatsbyImage
                image={images.image.childImageSharp.gatsbyImageData}
                alt="Common data environment (CDE) setup and implementation"
                title="Common data environment (CDE) setup and implementation"
              />
            </div>
          }
        />
        <ModuleSectionItem2
          title={t('bim_services_subtitle3')}
          variantTitle="h2"
          description={t('bim_services_description3')}
          btnText={t('Read more')}
          onClick={() => navigate(getPath('/execution-phase/'))}
          columnLeftChildren={
            <div className={s.lists}>
              <p className={s.text}>{t('Read more about our services:')}</p>
              <List>
                <li>{t('bim_services_list3.1')}</li>
                <li>{t('bim_services_list3.2')}</li>
                <li>{t('bim_services_list3.3')}</li>
                <li>{t('bim_services_list3.4')}</li>
              </List>
            </div>
          }
          columnRightChildren={
            <GatsbyImage
              image={images.image2.childImageSharp.gatsbyImageData}
              alt="On-Site BIM Support/\n BIM Field Coordination"
              title="On-Site BIM Support/\n BIM Field Coordination"
              objectFit="contain"
            />
          }
          reverseElements
        />
        <ModuleSectionItem2
          title={t('bim_services_subtitle4')}
          variantTitle="h2"
          description={t('bim_services_description4')}
          btnText={t('Read more')}
          onClick={() => navigate(getPath('/operational-phase/'))}
          columnLeftChildren={
            <div className={s.lists}>
              <p className={s.text}>{t('Read more about our services:')}</p>
              <List>
                <li>{t('bim_services_list4.1')}</li>
                <li>{t('bim_services_list4.2')}</li>
                <li>{t('bim_services_list4.3')}</li>
              </List>
            </div>
          }
          columnRightChildren={
            <div className="flex items-center">
              <GatsbyImage
                image={images.image3.childImageSharp.gatsbyImageData}
                alt="BIM Consulting and Management"
                title="BIM Consulting and Management"
              />
            </div>
          }
        />
      </ModuleSection2>
      <RequestDemo
        title={t('Know more')}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default BimServices

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "bim-services/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image: file(relativePath: { eq: "bim-services/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "bim-services/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "bim-services/image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
  }
`
